import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserClient from "../utils/Http/UserClient";

class FormPasswordSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            id: "",
            email: "",
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        try
        {
            const passwordsearch = {
                name: this.state.name,
                id: this.state.id,
                email: this.state.email,
            };

            const res = await UserClient.searchPassword(passwordsearch);

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            <form
                className="form m-10"
                id="contact-form"
                onSubmit={this.onSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>NAME</p>
                            <input
                                id="form_subject"
                                type="text"
                                name="name"
                                placeholder="이름을 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>ID</p>
                            <input
                                id="form_subject"
                                type="text"
                                name="id"
                                placeholder="아이디를 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>EMAIL</p>
                            <input
                                id="form_message"
                                type="text"
                                name="email"
                                placeholder="이메일을 입력하세요"
                                rows="4"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-0">
                        <p>등록된 이메일을 확인하세요.</p>
                    </div>
                    <div className="col-md-12 text-center mt-15">
                        <button type="submit" className="btn btn-lg">
                            확인
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default withRouter(FormPasswordSearch);
