import AxiosClient from "./AxiosClient";

const BookClient = {
    getBooks: async (data) => {
        return await AxiosClient.get('book/list', data);
    },

    getListAll: async (data) => {
        return await AxiosClient.get('book/listall', data);
    },

    getMonth: async (data) =>{
        return await AxiosClient.get(`book/month${data}`);
    },

    getTeacherBookList: async(data) => {
        return await AxiosClient.get('book/teacher', data);
    },

    getItems: async(data) => {
        return await AxiosClient.get(`book/item${data}`);
    },

    getTeacherLessonCount: async(data) => {
        return await AxiosClient.get('book/teacherLessonCount', data);
    },

    getTeacherLessonList: async(data) => {
        return await AxiosClient.get(`book/teacherLessonCount${data}`);
    },

    getTakeLessons: async(data) => {
        return await AxiosClient.get('book/takelessons', data);
    },

    create: async(id, data) => {
        return await AxiosClient.post(`book?_user=${id}`, data);
    },

    delete: async(data) => {
        return await AxiosClient.del('book/delete', data);
    },

    update: async(bookId, data) => {
        return await AxiosClient.patch(`book/update/${bookId}`, data);
    },

    updateTime: async(data) => {
        return await AxiosClient.patch('book/updateTime', data);
    },
}

export default BookClient;