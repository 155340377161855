import dotenv from "dotenv";

export const serverUrl =
    process.env.REACT_APP_WEB_ENV === "product"
        ? `${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_URL}/`
        : `${process.env.REACT_APP_API_DEV_HTTP}://${process.env.REACT_APP_API_DEV_URL}:${process.env.REACT_APP_API_DEV_PORT}/`;

export const apiUrl =
    process.env.REACT_APP_WEB_ENV === "product"
        ? `${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ADD_URL}`
        : `${process.env.REACT_APP_API_DEV_HTTP}://${process.env.REACT_APP_API_DEV_URL}:${process.env.REACT_APP_API_DEV_PORT}${process.env.REACT_APP_API_ADD_DEV_URL}`;
