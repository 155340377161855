import React, { Component } from "react";
import moment from "moment";
import Aside from "../components/Aside";
import TableTakeLesson from "../components/TableTakeLesson";
import { Link } from "react-router-dom";
import mlblogo from "../img/mlblogo.png";
import jwt_decode from "jwt-decode";
import UserClient from "../utils/Http/UserClient";
import PointClient from "../utils/Http/PointClient";
import BranchClient from "../utils/Http/BranchClient";
import ReactPaginate from "react-paginate";

class Point extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _id: "",
      banner: "",
      list: [],
      histories: [],
      page: 0,
      countPerPage: 10,
      _branch: "",
      pageCount: 0,
      totalCount: 0,
    };
  }

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      console.log(decoded);
      const response = await UserClient.get(decoded._id);
      if (!response.data.success) {
        alert("계정 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.setState(
        {
          _id: decoded._id,
          _branch: response.data.result._branch,
        }, () => this.loadBanner()
      );
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  loadPoints = async () => {
    try {
      const response = await PointClient.list(this.state._id);
      if (!response.data.success) {
        alert("적립금 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.setState(
        {
          list: response.data.success ? response.data.list : [],
        },
        () => this.loadHistory()
      );
    } catch (err) {
      // console.log(err);
      alert("적립금 정보를 불러올 수 없습니다.\n" + err);
    }
  };

  loadHistory = async () => {
    try {
      const response = await PointClient.history(this.state._id, this.state.page, this.state.countPerPage);
      if (!response.data.success) {
        alert("히스토리 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.setState({
            histories: response.data.result.list ? response.data.result.list : [],
            pageCount:
                Number.parseInt(
                    (response.data.result.count - 1) / this.state.countPerPage
                ) + 1,
            totalCount: response.data.result.count,
        });
    } catch (err) {
      // console.log(err);
      alert("적립금 정보를 불러올 수 없습니다.\n" + err);
    }
  };

  loadBanner = async () => {
    try {
      const response = await BranchClient.getBanner(this.state._branch);

      // const test = `<b style='color:white !important'>비밀번호 0000</b>\n연주회 5/1\n상담가능시간 14:00~22:00`;
      // console.log(response.data.result.banner);
      this.setState({
        banner:
          response.data.success && response.data.result.banner
            ? response.data.result.banner
            : "",
      }, () => this.loadPoints());
    } catch (err) {
      console.log(err);
      alert("배너 정보를 불러올 수 없습니다.");
    }
  };

  onClickHistory = async (id, name) => {
    this.setState({ branchId: id, branchName: name }, () => {

    });
  }

  onClickPage = (e) => {
    const selectedPage = e.selected;

    this.setState(
        {
          page: selectedPage,
        },
        () => {
          this.loadHistory();
        }
    );
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Aside hasBanner={this.state.banner !== "" ? true : false} />

        <div className="togo-side-content" style={{width: "100%"}}>
          {this.state.banner == "" ? null : (
              <div className="banner display-enter">
                <div
                    className="banner-text"
                    style={{color: "white"}}
                    dangerouslySetInnerHTML={{__html: this.state.banner}}
                />
              </div>
          )}
          {/* <div className="text-center header-logo">
            <Link to="/">
              <img
                style={{
                  maxWidth: 240,
                  marginBottom: "30px",
                  padding: "5px 10px",
                }}
                src={mlblogo}
                alt=""
                id="logoimage"
              />
            </Link>
          </div> */}
          <div className="container">
            <div className="pt-70"></div>
            <section className="about pb-100">
              <div className="container pt-20" id="mw-880">
                <div className="row m-10 text-center">
                  <div className="col-md-12 text-center">
                    <div className="container mb-40">
                      <h6 className="">적립금</h6>
                    </div>
                    {this.render_points()}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="container">
            <div className="container" id="mw-880">
              <div className="row m-10 text-center">
                <div className="col-md-12 text-center">
                  <div className="container mb-40">
                    <h6>상세내역</h6>
                  </div>
                  {this.render_point_history()}
                </div>
              </div>
            </div>
          </div>


          <div className="container">
            <section className="about pb-100">
              <div className="container pt-20" id="mw-880">
                <div className="row m-10 text-center">
                  <div className="col-md-12 text-center">
                    <div className="container mb-40">
                      <h5 style={{fontWeight: "bold"}}>포인트 사용</h5>
                      적립된 포인트는 해당지점에서 재등록시 현금처럼 사용하실 수 있습니다.
                    </div>
                    <div className="container mb-40">
                      <h5 style={{fontWeight: "bold"}}>포인트 주의사항</h5>
                      <div>
                        수강권 결제 후 포인트를 적립받은 경우, 결제를 취소하시면 포인트도 같이 취소됩니다.
                      </div>
                      <div>
                        각 지점별로 포인트가 적립 및 관리됩니다.
                      </div>
                    </div>
                    <div className="container mb-40">
                      <h5 style={{fontWeight: "bold"}}>포인트 소멸</h5>
                      수강 종료일로부터 12개월동안 포인트 적립 및 사용 실적이 없는 회원의 포인트는 모두 소멸됩니다
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }

  render_points() {
    const list = {};

    this.state.list.forEach((point) => {
      console.log(point);
      if (!list[point._branch._id]) {
        list[point._branch._id] = {
          point: 0,
          name: point._branch.name
        };
      }

      list[point._branch._id].point += point.amount;
    })

    return (
        <div>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
              <tr>
                <th>지점</th>
                <th>포인트</th>
              </tr>
              </thead>
              <tbody>
              {Object.keys(list).map((key, index) => {
                return (
                    <tr>
                      <td>{list[key].name}</td>
                      <td>{list[key].point.toLocaleString('en-US')}</td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </div>
    );
  }

  render_point_history() {
    return (
        <div>
          <div className="table-responsive text-nowrap">
            <table className="table">
              <thead>
              <tr>
                <th>지점</th>
                <th>포인트</th>
                <th>일시</th>
              </tr>
              </thead>
              <tbody>
              {this.state.histories.map((history, index) => {
                return (
                    <tr>
                      <td>{history._branch.name}</td>
                      <td>{history.amount.toLocaleString('en-US')}</td>
                      <td>{moment(history.create_at).format('YYYY-MM-DD HH:mm')}</td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </div>
          <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.onClickPage}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
          />
        </div>
    );
  }
}

export default Point;
