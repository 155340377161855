import React, { Component } from "react";
// import { withRouter } from 'react-router-dom';
import moment from "moment";
// import jwt_decode from "jwt-decode";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import BookClient from "../utils/Http/BookClient";
import TicketClient from "../utils/Http/TicketClient";
import Constant from "../common/Constants";

class TableBook extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    // context 사용해서 로그인한 사용자 정보 가져오기
    this.state = {
      // lesson_at: '',
      // lesson_time: '',
      // _user: ''
      books: [],
      errorMsg: "",
      offset: 0,
      // tableData: [],
      orgtableData: [],
      perPage: 5,
      currentPage: 0,
      tickets: [],
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.loadMoreData();
      }
    );
  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      books: slice,
    });
  }

  componentDidMount = async () => {
    // console.log(this.context);
    try {
      const res = await BookClient.getBooks({
        _user: this.context._id,
      });
      var data = res.data.result;
      var slice = data.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        // books: res.data.result,

        pageCount: Math.ceil(data.length / this.state.perPage),
        orgtableData: res.data.result,
        books: slice,
      });

      // console.log(this.context._id);

      const ticket_res = await TicketClient.getList({
        _user: this.context._id,
      });
      this.setState({ tickets: ticket_res.data.result });
    } catch (err) {
      console.log(err);
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  handleRemove = async (e) => {
    const id = e.target.dataset.book;
    try {
      if (window.confirm("취소하시겠습니까?") === true) {
        const { books } = this.state;
        this.setState({
          book: books.filter((book) => book.id !== id),
        });
        const res = await BookClient.update(id, { status: "cancel" });

        if (res.data.success) {
          window.location.reload();
        } else {
          alert(res.data.message);
        }
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  setDayOfWeek = (date) => {
    const dow = moment(date).day();
    let day = "";
    if (dow === 0) day = "일";
    if (dow === 1) day = "월";
    if (dow === 2) day = "화";
    if (dow === 3) day = "수";
    if (dow === 4) day = "목";
    if (dow === 5) day = "금";
    if (dow === 6) day = "토";

    return day;
  };

  render() {
    const { books, errorMsg } = this.state;

    const token = localStorage.getItem("token");

    return (
      <div>
        <div className="mb-3 mr-2 text-right">
          시작일 / 종료일 :{" "}
          {this.state.tickets.length > 0
            ? moment(this.state.tickets[0].startDate).format("YYYY-MM-DD")
            : ""}{" "}
          /{" "}
          {this.state.tickets.length > 0
            ? moment(this.state.tickets[0].endDate).format("YYYY-MM-DD")
            : ""}
        </div>
        <div className="mb-3 mr-2 text-right">
          잔여 / 총 레슨횟수 :{" "}
          {this.state.tickets.length > 0 ? this.state.tickets[0].lesson : 0} /{" "}
          {this.state.tickets.length > 0
            ? this.state.tickets[0].lesson_all +
              this.state.tickets[0].lesson_buy +
              this.state.tickets[0].lesson_carry_over
            : 0}
        </div>
        <div className="table-responsive text-nowrap">
          <table className="table">
            <thead>
              <tr>
                <th>예약일</th>
                <th>예약시간</th>
                <th>레슨시간</th>
                <th>강사</th>
                <th>과목</th>
                {/* <th>수강내역</th> */}
                {/* <th>남은/총 레슨횟수</th> */}
                <th>예약취소</th>
              </tr>
            </thead>
            <tbody>
              {books.length
                ? books.map(
                    (book, index) => (
                      // book._user === decoded._id ?

                      <tr key={index}>
                        <td>
                          {moment(book.lesson_at).format("YY.MM.DD")} (
                          {this.setDayOfWeek(book.lesson_at)})
                        </td>
                        <td>{book.lesson_time}</td>
                        <td>{Constant.lessonTimeByType[book.lesson_type]}분</td>
                        <td>
                          {book._teacher
                            ? book._teacher.name
                            : book.teacher_name}
                        </td>
                        <th>{book._subject ? book._subject.name : ""}</th>
                        {/* <th>{book._user.lesson_number} / {book._user.lesson_number_all}</th> */}
                        {book.status === "apply" ? (
                          <td>
                            <button
                              className="btn btn-sm"
                              data-book={book._id}
                              onClick={this.handleRemove}
                            >
                              예약취소
                            </button>
                          </td>
                        ) : (
                          <td>
                            <button
                              className="btn btn-sm"
                              data-book={book._id}
                              disabled
                            >
                              취소완료
                            </button>
                          </td>
                        )}
                      </tr>
                    )
                    // : null
                  )
                : null}
              {errorMsg ? <div>{errorMsg}</div> : null}
            </tbody>
          </table>
        </div>

        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={this.state.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    );
  }
}

export default TableBook;
