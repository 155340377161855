import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import Validation from "../utils/Validations";
import BranchClient from "../utils/Http/BranchClient";
import UserClient from "../utils/Http/UserClient";

class FormUserModify extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            email: "",
            password: "",
            password1: "",
            password2: "",
            name: "",
            phone: "",
            gender: "",
            birth_year: "",
            birth_month: "",
            birth_day: "",
            users: [],
            _branch: "",
            branchArr: [],
            update_at: Date.now(),
        };
    }

    componentDidMount = async () => {
        try {
            const response = await UserClient.get(this.props.match.params.id);
            const branchList = await BranchClient.getList();
            this.setState({
                id: response.data.result.id,
                email: response.data.result.email,
                password: response.data.result.password,
                name: response.data.result.name,
                phone: response.data.result.phone,
                gender: response.data.result.gender,
                birth_year: response.data.result.birth_year,
                birth_month: response.data.result.birth_month,
                birth_day: response.data.result.birth_day,
                update_at: this.state.update_at,
                _branch: response.data.result._branch,
                branchArr: branchList.data.success
                    ? branchList.data.result
                    : [],
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleRemove = async (e) => {
        e.preventDefault();

        const remove = {
            id: this.state.id,
            password: this.state.password,
        };

        try
        {
            const res = await UserClient.remove(this.props.match.params.id, remove);
            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/logout`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            alert(err.message + "실패");
        }
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const idValidation = await Validation.id(this.state.id);
        if (!idValidation.success) {
            alert(idValidation.message);
        } else {
            const modify = {
                id: this.state.id,
                email: this.state.email,
                password: this.state.password,
                password1: this.state.password1,
                password2: this.state.password2,
                name: this.state.name,
                phone: this.state.phone,
                gender: this.state.gender,
                birth_year: this.state.birth_year,
                birth_month: this.state.birth_month,
                birth_day: this.state.birth_day,
                _branch: this.state._branch,
                update_at: this.state.update_at,
            };
            try
            {
                const res = await UserClient.update(this.props.match.params.id, modify);
                if (res.data.success === true) {
                    alert(res.data.message);
                    this.props.history.push("/");
                    window.location.reload();
                } else {
                    alert(res.data.message);
                }
            }
            catch(err)
            {
                console.log(err);
                alert(err.message + "실패");
            }
        }
    };

    render() {
        let yearArr = [];

        const thisyear = moment().format("yyyy");

        for (var i = 0; i < 82; i++) {
            yearArr.push(thisyear - i);
        }

        // const year = [1940,1941,1942,1943,1944,1945,1946,1947,1948,1949,1950,1951,1952,1953,1954,1955,1956,1957,1958,1959,1960,1961,1962,1963,1964,1965,1966,1967,1968,1969,1970,1971,1972,1973,1974,1975,1976,1977,1978,1979,1980,1981,1982,1983,1984,1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021];
        const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const day = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];

        return (
            <form
                className="form m-10"
                id="contact-form"
                onSubmit={this.onSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                ID&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_subject"
                                type="text"
                                name="id"
                                readOnly
                                defaultValue={this.state.id}
                                placeholder="아이디를 입력하세요"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                NOW PASSWORD&nbsp;
                                <span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password"
                                placeholder="현재 비밀번호를 입력하세요"
                                rows="4"
                                onChange={this.onChange}
                                required="required"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                NEW PASSWORD&nbsp;
                                <span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password1"
                                placeholder="새 비밀번호를 입력하세요"
                                rows="4"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                NEW PASSWORD CHECK&nbsp;
                                <span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password2"
                                placeholder="새 비밀번호를 확인합니다"
                                rows="4"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                NAME&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="text"
                                name="name"
                                placeholder="이름을 입력하세요"
                                rows="4"
                                defaultValue={this.state.name}
                                onChange={this.onChange}
                                required="required"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                EMAIL&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_subject"
                                type="text"
                                name="email"
                                defaultValue={this.state.email}
                                placeholder="이메일을 입력하세요"
                                onChange={this.onChange}
                                required="required"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                BIRTH&nbsp;<span className="colorred">*</span>
                            </p>
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        onChange={this.onChange}
                                        name="birth_year"
                                        value={this.state.birth_year}
                                        required="required"
                                    >
                                        {yearArr.map((year, index) => (
                                            <option
                                                key={index}
                                                value={year}
                                                name="birth_year"
                                            >
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        onChange={this.onChange}
                                        name="birth_month"
                                        value={this.state.birth_month}
                                        required="required"
                                    >
                                        {month.map((month, index) => (
                                            <option
                                                key={index}
                                                value={month}
                                                name="birth_month"
                                            >
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        onChange={this.onChange}
                                        name="birth_day"
                                        value={this.state.birth_day}
                                        required="required"
                                    >
                                        {day.map((day, index) => (
                                            <option
                                                key={index}
                                                value={day}
                                                name="birth_day"
                                            >
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                GENDER&nbsp;<span className="colorred">*</span>
                            </p>
                            <select
                                className="custom-select my-1 mr-sm-2"
                                name="gender"
                                onChange={this.onChange}
                                value={this.state.gender}
                                required="required"
                            >
                                <option value="여성" name="gender">
                                    여성
                                </option>
                                <option value="남성" name="gender">
                                    남성
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                PHONE&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="text"
                                name="phone"
                                placeholder="예시) 010-0000-0000"
                                rows="4"
                                defaultValue={this.state.phone}
                                onChange={this.onChange}
                                required="required"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                지점&nbsp;<span className="colorred">*</span>
                            </p>
                            <select
                                className="custom-select"
                                name="_branch"
                                onChange={this.onChange}
                                value={
                                    this.state._branch
                                        ? this.state._branch.name
                                        : null
                                }
                                required="required"
                            >
                                <option
                                    value={
                                        this.state._branch
                                            ? this.state._branch.name
                                            : null
                                    }
                                    disabled
                                >
                                    {this.state._branch
                                        ? this.state._branch.name
                                        : null}
                                </option>
                                {this.state.branchArr.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-15">
                        <Link to="/">
                            <button type="button" className="btn btn-lg m-1">
                                취소하기
                            </button>
                        </Link>
                        <button type="submit" className="btn btn-lg m-1">
                            수정하기
                        </button>
                    </div>
                </div>
                <div className="text-center mt-50">
                    <button className="btn btn-sm" onClick={this.handleRemove}>
                        탈퇴하기
                    </button>
                </div>
            </form>
        );
    }
}

export default withRouter(FormUserModify);
