import React, { Component } from "react";
// import { withRouter, Link, Redirect } from 'react-router-dom';
import { withRouter, Link } from "react-router-dom";
import UserClient from "../utils/Http/UserClient";

class FromLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            password: "",
        };
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        try
        {
            const res = await UserClient.login({
                id: this.state.id,
                password: this.state.password,
            })
            if (res.data.success === true) {
                localStorage.setItem("token", res.data.result);
                console.log(res.data.result);
                this.props.history.push("/ticketlist");
                // window.location.reload();
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            // <form className="form m-10" id="contact-form" onSubmit={this.handleSubmit} >
            <form className="form m-10" onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>ID</p>
                            {/* <input id="form_subject" type="text" name="email" required placeholder="이메일을 입력하세요"
                            onChange={e => this.email = e.target.value}
                        /> */}
                            <input
                                type="text"
                                name="id"
                                required="required"
                                placeholder="아이디를 입력하세요"
                                onChange={(e) =>
                                    this.setState({
                                        [e.target.name]: String(
                                            e.target.value
                                        ).toLowerCase(),
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>PASSWORD</p>
                            {/* <input id="form_message" type="text" name="password" placeholder="비밀번호를 입력하세요" rows="4" required="required"
                            onChange={e => this.password = e.target.value}
                        /> */}
                            <input
                                type="password"
                                name="password"
                                required="required"
                                placeholder="비밀번호를 입력하세요"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-0">
                        <button type="submit" className="btn btn-lg">
                            LOGIN
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default withRouter(FromLogin);
