import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import FormLogin from '../components/FormLogin';

class Logout extends Component {
    constructor(props) {
        super(props);
        localStorage.removeItem("token");
        this.props.history.push("/");
    }
    render() {
        return (
            <div></div>
            // <section className="about pb-100">
            //     <div className="container pt-20"  id="mw-300">
            //         <div className="row m-10 text-center">
            //             <div className="col-md-12 text-center">
            //                 <div className="container mb-30">
            //                     <h6>로그아웃 되었습니다.</h6>
            //                 </div>
            //                 <Link to="/">확인</Link>
            //             </div>
            //         </div>
            //     </div>
            // </section>
        );
    }
}

export default withRouter(Logout);
