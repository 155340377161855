import AxiosClient from "./AxiosClient";

const UserClient = {
  create: async (data) => {
    return await AxiosClient.post("user", data);
  },

  delete: async (data) => {
    return await AxiosClient.del("user/delete", data);
  },

  get: async (data) => {
    return await AxiosClient.get(`user/list/${data}`);
  },
  getList: async (data) => {
    return await AxiosClient.get("user/list", data);
  },

  updateState: async (id, data) => {
    return await AxiosClient.patch(`user/update/state/${id}`, data);
  },

  update: async (id, data) => {
    return await AxiosClient.patch(`user/update/${id}`, data);
  },

  login: async (data) => {
    return await AxiosClient.post("user/login", data);
  },

  searchId: async (data) => {
    return await AxiosClient.post("user/idsearch", data);
  },

  searchPassword: async (data) => {
    return await AxiosClient.post("user/passwordsearch", data);
  },

  reset: async (id, data) => {
    return await AxiosClient.patch(`user/reset/${id}`, data);
  },

  remove: async (id, data) => {
    return await AxiosClient.patch(`user/update/${id}`, data);
  },

  getKakaoLogin: async () => {
    return await AxiosClient.get(`user/kakaologin`);
  },

  kakaoConnect: async (id, data) => {
    return await AxiosClient.patch(`user/kakaoconnect/${id}`, data);
  },

  kakaoDisConnect: async (id, data) => {
    return await AxiosClient.patch(`user/kakaodisconnect/${id}`, data);
  },

  couponList: async (id) => {
    return await AxiosClient.get(`user/coupon/${id}`);
  },
};

export default UserClient;
