import React, { Component } from "react";
import moment from "moment";
import Aside from "../components/Aside";
import TableTakeLesson from "../components/TableTakeLesson";
import { Link } from "react-router-dom";
import mlblogo from "../img/mlblogo.png";
import jwt_decode from "jwt-decode";
import UserClient from "../utils/Http/UserClient";
import BranchClient from "../utils/Http/BranchClient";
import ReactPaginate from "react-paginate";

class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: "",
      coupons: [],
    };
  }

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      console.log(decoded);
      const response = await UserClient.get(decoded._id);
      if (!response.data.success) {
        alert("계정 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      // console.log(response.data.result, response.data.result.coupons);

      this.setState(
        {
          coupons: response.data.result.coupons
            ? response.data.result.coupons
            : [],
        },
        () => this.loadBanner(response.data.result._branch._id)
      );
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  loadBanner = async (_branch) => {
    try {
      // console.log(this.state.coupons);
      const response = await BranchClient.getBanner(_branch);

      // const test = `<b style='color:white !important'>비밀번호 0000</b>\n연주회 5/1\n상담가능시간 14:00~22:00`;
      // console.log(response.data.result.banner);
      this.setState({
        banner:
          response.data.success && response.data.result.banner
            ? response.data.result.banner
            : "",
      });
    } catch (err) {
      console.log(err);
      alert("지점 정보를 불러올 수 없습니다.");
    }
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Aside hasBanner={this.state.banner !== "" ? true : false} />

        <div className="togo-side-content" style={{ width: "100%" }}>
          {this.state.banner == "" ? null : (
            <div className="banner display-enter">
              <div
                className="banner-text"
                style={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: this.state.banner }}
              />
            </div>
          )}
          {/* <div className="text-center header-logo">
            <Link to="/">
              <img
                style={{
                  maxWidth: 240,
                  marginBottom: "30px",
                  padding: "5px 10px",
                }}
                src={mlblogo}
                alt=""
                id="logoimage"
              />
            </Link>
          </div> */}
          <div className="container">
            <div className="pt-70"></div>
            <section className="about pb-100">
              <div className="container pt-20" id="mw-880">
                <div className="row m-10 text-center">
                  <div className="col-md-12 text-center">
                    <div className="container mb-40">
                      <h6 className="">쿠폰함</h6>
                    </div>
                    {this.render_coupons()}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }

  render_coupons() {
    return (
      <div>
        <div className="table-responsive text-nowrap">
          <table className="table">
            <thead>
              <tr>
                <th>쿠폰명</th>
                <th>할인액</th>
                <th>사용기간</th>
                <th>사용여부</th>
              </tr>
            </thead>
            <tbody>
              {this.state.coupons.map((coupon, index) => (
                <tr key={index}>
                  <td>{coupon.name}</td>
                  <td>{coupon.price.toLocaleString('en-US')} 원</td>
                  <td>{`${moment(coupon.started_at).format(
                    "YYYY-MM-DD"
                  )} ~ ${moment(coupon.ended_at).format("YYYY-MM-DD")}`}</td>
                  <td>{coupon.used ? '사용완료' : '미사용'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default Coupon;
