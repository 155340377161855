import { useState, useEffect } from "react";
import NoticePopupItem from './NoticePopupItem';
import NoticePopupClient from "../utils/Http/NoticePopupClient";
import moment from 'moment';

export default function NoticePopup () {

    const [items, setItems] = useState([]);

    useEffect(() => {
        load();
    }, [])

    async function load () {
        try
        {
            const response = await NoticePopupClient.list();

            const data = response.data;
            if(!data.success)
            {
                alert(data.message);
                return;
            }

            const filtered = data.result.filter((item) => !isNoDisplayToday(item._id) && !isNoDisplayAllDay(item._id));

            setItems(filtered);
        }
        catch(ex)
        {
            alert(ex.message + "실패");
        }
    }

    function isNoDisplayToday(id)
    {
        var cache = JSON.parse(localStorage.getItem("notice_popup_no_display_one_day"));
        console.log("isNoDisplayToday",cache);
        if(!cache)
            return false;
        const item = cache.find((item) => item.id == id);
        if(!item)
            return false;

        if(0 < moment().diff(item.date, 'days'))
            return false;
        
        return true;
    }

    function isNoDisplayAllDay(id)
    {
        var cache = JSON.parse(localStorage.getItem("notice_popup_no_display_all_day"));
        console.log('isNoDisplayAllDay',cache);
        if(!cache)
            return false;

        return cache.some((item) => item == id);
    }

    function addNoticePopupNoDisplayToday(id)
    {
        var cache = JSON.parse(localStorage.getItem("notice_popup_no_display_one_day"));
        const today = moment();

        if(!cache) {
            cache = [];
        }

        cache = cache.filter((item) => item.id != id);

        cache.push({id: id, date: today});

        localStorage.setItem("notice_popup_no_display_one_day", JSON.stringify(cache));
    }

    function addNoticePopupNoDisplayAllDay(id)
    {
        var cache = JSON.parse(localStorage.getItem("notice_popup_no_display_all_day"));

        if(!cache) {
            cache = [];
        }

        cache = cache.filter((item) => item != id);

        cache.push(id);

        localStorage.setItem("notice_popup_no_display_all_day", JSON.stringify(cache));
    }

    function closeModal(id) {
        setItems(items.filter((item) => item._id != id));
    }

    function closeModalToday(id) {
        addNoticePopupNoDisplayToday(id);
        setItems(items.filter((item) => item._id != id));
    }

    function closeModalAllDay(id) {
        addNoticePopupNoDisplayAllDay(id);
        setItems(items.filter((item) => item._id != id));
    }

    return (
        <>
           {items.map((item) => {
                return (
                    <NoticePopupItem 
                        isOpen={true} 
                        image_url={item.image_url} 
                        closeModal={() => closeModal(item._id)} 
                        closeModalToday={() => closeModalToday(item._id)}
                        closeModalAllDay={() => closeModalAllDay(item._id)}
                    />
                );
           })}
        </>
    );
}