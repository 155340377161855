import React from "react";
import { withRouter } from "react-router-dom";
import Calendar1 from "react-calendar";
import moment from "moment";
import "moment-timezone";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../components/AuthenticatedComponent";
import UserClient from "../utils/Http/UserClient";
import queryString from "query-string";

moment.tz.setDefault("Asia/Seoul");

class KakaoConnect extends React.Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props);

        this.state = {
        };
    }

    componentDidMount = async () => {
        try {
            const query = queryString.parse(this.props.location.search);
            if(query.error) {
                alert("카카오 연동에 실패하였습니다.");
                window.location.href = "/";
                return;
            }

            const token = localStorage.getItem("token");
            const decoded = jwt_decode(token);
            console.log(query.code);
            const res = await UserClient.kakaoConnect(decoded._id, {
                code: query.code
            });

            if(res.data.success === false) {
                alert("카카오 연동에 실패하였습니다.");
                window.location.href = "/";
                return;
            }

            alert("연동 성공");
            window.location.href = "/";
        } catch (error) {
            this.setState({ errorMsg: "Error retreiving data" });
        }
    };

    render() {
        return (
            <>
            </>
        );
    }
}

export default withRouter(KakaoConnect);
