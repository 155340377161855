import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AuthenticatedComponent from "./components/AuthenticatedComponent";
import TicketList from "./pages/TicketList";
import Book from "./pages/Book";
import BookList from "./pages/BookList";
import TakeLessonList from "./pages/TakeLessonList";
import Coupon from "./pages/Coupon";
import Point from "./pages/Point";
import Suggestion from "./pages/Suggestion";
import IdSearch from "./pages/IdSearch";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PasswordReset from "./pages/PasswordReset";
import PasswordSearch from "./pages/PasswordSearch";
import Signup from "./pages/Signup";
import SignupAgree from "./pages/SignupAgree";
import UserModify from "./pages/UserModify";
import KakaoConnect from "./pages/KakaoConnect";

if (process.env.REACT_APP_WEB_ENV === "production") {
  console.log = function no_console() {};
  console.warn = function no_console() {};
}

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/ticketlist" />
          </Route>
          <Route path="/login" exact component={Login} />
          <Route path="/idsearch" exact component={IdSearch} />
          <Route path="/passwordsearch" exact component={PasswordSearch} />
          <Route path="/passwordreset/:id" exact component={PasswordReset} />
          <Route path="/signupagree" exact component={SignupAgree} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/kakaoconnect" exact component={KakaoConnect} />
          <AuthenticatedComponent>
            <Route path="/usermodify/:id" exact component={UserModify} />
            <Route path="/ticketlist" exact component={TicketList} />
            <Route path="/book" exact component={Book} />
            <Route path="/booklist" exact component={BookList} />
            <Route path="/takelessonlist" exact component={TakeLessonList} />
            <Route path="/coupon" exact component={Coupon} />
            <Route path="/point" exact component={Point} />
            <Route path="/suggestion" exact component={Suggestion} />
          </AuthenticatedComponent>
        </Switch>
      </Router>
    );
  }
}
export default App;
