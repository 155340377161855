import React, { Component } from "react";
import Aside from "../components/Aside";
import FormUserModify from "../components/FormUserModify";
import { Link } from "react-router-dom";
import mlblogo from "../img/mlblogo.png";

class UserModify extends Component {
    render() {
        return (
            <div className="d-flex" id="wrapper">
                <Aside />

                <div className="togo-side-content" style={{ width: "100%" }}>
                    {/* <div className="text-center header-logo">
            <Link to='/'><img style={{
                              maxWidth: 240,
                              marginBottom: '30px',
                              padding: '5px 10px'
                          }} src={mlblogo} alt="" id="logoimage"/></Link>
          </div> */}
                    <div className="container">
                        <div className="pt-70"></div>
                        <section className="about pb-100">
                            <div className="container pt-20" id="mw-400">
                                <div className="row m-10 text-center">
                                    <div className="col-md-12 text-center">
                                        <div className="container mb-30">
                                            <h6>회원정보수정</h6>
                                        </div>
                                        <FormUserModify />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserModify;
