import React from "react";
import { withRouter } from "react-router-dom";
import Calendar1 from "react-calendar";
import moment from "moment";
import "moment-timezone";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../components/AuthenticatedComponent";
import AdminClient from "../utils/Http/AdminClient";
import BookClient from "../utils/Http/BookClient";
import ScheduleClient from "../utils/Http/ScheduleClient";
import EventClient from "../utils/Http/EventClient";
import HolidayClient from "../utils/Http/HolidayClient";
import TicketClient from "../utils/Http/TicketClient";
import Constants from "../common/Constants";

// import "react-calendar/dist/Calendar.css"; // css import

moment.tz.setDefault("Asia/Seoul");

const teacherOrder = {};
teacherOrder["피아노"] = 1;
teacherOrder["재즈피아노"] = 2;
teacherOrder["바이올린"] = 3;
teacherOrder["성악/뮤지컬"] = 4;
teacherOrder["피아니스트코스"] = 5;

const scheduleState = {
  Open: 1,
  Close: 2,
  Booked: 3,
};

class FormBook extends React.Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);

    let today = new Date();
    today.setDate(new Date().getDate());
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    // console.log(context);
    this.state = {
      _branch: context._branch,
      // branchArr: [],
      _subject: "",
      _teacher: null,
      teacherArr: [],
      lesson_time: "",
      lesson_at: tomorrow,
      tomorrow: tomorrow,
      today: today,
      _user: decoded._id,
      errorMsg: "",
      dayOfWeek: "",
      unable_schedule: [],
      schedule: [],
      times: [
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
      isSubmitting: false,
      events: [],
      holidays: [],
      availableTeacherList: [],
      tickets: [],
    };
  }

  setDayOfWeek(date) {
    const dow = moment(date).day();
    let day = "";
    if (dow === 0) day = "sunday";
    if (dow === 1) day = "monday";
    if (dow === 2) day = "tuesday";
    if (dow === 3) day = "wednesday";
    if (dow === 4) day = "thursday";
    if (dow === 5) day = "friday";
    if (dow === 6) day = "saturday";

    return day;
  }

  isOpenedDate = (teacher, schedule, dow, date) => {
    const formatted_date = moment(date).format("YYYY-MM-DD");

    if (
      formatted_date <
        moment(teacher.contract_period.start).format("YYYY-MM-DD") ||
      moment(teacher.contract_period.end).format("YYYY-MM-DD") < formatted_date
    )
      return false;

    return Object.values(schedule).some(
      (item) =>
        item.openOnTime == Constants.scheduleState.Open ||
        item.openOnTime == Constants.scheduleState.Booked ||
        item.openHalf == Constants.scheduleState.Open ||
        item.openHalf == Constants.scheduleState.Booked
    );
  };

  isValidTeacherForFullTime = (schedule) => {
    return true;
  };

  isValidTeacherForHalfTime = (schedule) => {
    let valid = false;
    Constants.times.forEach((time) => {
      if (schedule[time].allowHalfTime) valid = true;
    });

    return valid;
  };

  getAvailableTeacherList = (teacherArr, schedules, dow, date) => {
    const formatted_date = moment(date).format("YYYY-MM-DD");
    const ticket = this.state.tickets.find(
      (ticket) =>
        moment(ticket.startDate).format("YYYY-MM-DD") <= formatted_date &&
        formatted_date <= moment(ticket.endDate).format("YYYY-MM-DD")
    );
    // console.log(ticket, date);
    if (!ticket) return [];
    let availableTeacherList = teacherArr.filter((teacher) => {
      var schedule = schedules[teacher._id];

      if (!schedule) return false;
      // console.log(schedule);
      if (!this.isOpenedDate(teacher, schedule, dow, date)) return false;

      // if (ticket.ticketType == Constants.ticketType[50])
      //   return this.isValidTeacherForFullTime(schedule);
      // else return this.isValidTeacherForHalfTime(schedule);
      return true;
    });

    return availableTeacherList;
  };

  async fetchData(lesson_at, _branch, teacherList) {
    // let start = new Date();
    const lessonAt = moment(lesson_at).format("YYYY-MM-DD");
    const teacherL = await BookClient.getBooks({
      _branch: _branch,
      lesson_at: lessonAt,
      status: "apply",
    });
    // console.log(_branch);

    const teacherLL = teacherL.data.result;

    var bookList = [];
    teacherLL.forEach((item) => {
      if (!bookList[item._teacher._id]) {
        bookList[item._teacher._id] = [];
      }
      bookList[item._teacher._id].push({
        teacher: item._teacher._id,
        time: item.lesson_time,
        type: "booked",
      });
    });

    const dow = this.setDayOfWeek(lesson_at);
    const dowNum = moment(lesson_at).day();
    // let schedule = [];
    let lessTime = true;

    const scheduleRes = await ScheduleClient.getScheduleAll({
      _branch: _branch,
      lessonDate: lessonAt,
    });
    var schedules = {};

    if (scheduleRes.data.success) {
      scheduleRes.data.result.schedules.forEach((schedule) => {
        // console.log(schedule);
        schedules[schedule._teacher] = {};

        Constants.times.forEach((time) => {
          var initValue = {
            allowHalfTime: false,
            openOnTime: Constants.scheduleState.Open,
            openHalf: Constants.scheduleState.Close,
          };

          // console.log(dow)
          if (schedule.allowHalfTimes[dowNum]) {
            initValue.allowHalfTime = true;
            initValue.openHalf = Constants.scheduleState.Open;
          }
          schedules[schedule._teacher][time] = initValue;
          // console.log(schedules[schedule._teacher][time]);
        });

        schedule.unable_schedule.forEach((unable) => {
          var splits = unable.time.split(":");
          if (dowNum == unable.dow) {
            // console.log(unable, unable.time);
            const time = `${splits[0]}:00`;
            if (splits[1] == "00") {
              schedules[schedule._teacher][time].openOnTime =
                Constants.scheduleState.Close;
            } else {
              schedules[schedule._teacher][time].openHalf =
                Constants.scheduleState.Close;
            }
          }
        });
      });

      scheduleRes.data.result.temporarySchedules.forEach(
        (temporarySchedule) => {
          const splits = temporarySchedule.lessonTime.split(":");
          const hour = splits[0];
          const minutes = splits[1];
          const schedule = schedules[temporarySchedule._teacher][`${hour}:00`];

          if (minutes == "00") {
            schedule.openOnTime =
              temporarySchedule.status == 1
                ? Constants.scheduleState.Open
                : Constants.scheduleState.Close;
          } else {
            schedule.openHalf =
              temporarySchedule.status == 1
                ? Constants.scheduleState.Open
                : Constants.scheduleState.Close;
          }
        }
      );

      scheduleRes.data.result.temporaryScheduleHalfTimes.forEach(
        (temporaryScheduleHalfTime) => {
          const splits = temporaryScheduleHalfTime.lessonTime.split(":");
          const hour = splits[0];
          const schedule =
            schedules[temporaryScheduleHalfTime._teacher][`${hour}:00`];
          schedule.allowHalfTime =
            temporaryScheduleHalfTime.type == 1 ? true : false;
        }
      );
      // console.log(schedules);
      // console.log(teacherLL);
      teacherLL.forEach((book) => {
        // if (book.status == "cancel") return;
        const schedule = schedules[book._teacher._id];
        // console.log(book, schedule);
        if (!schedule) return;

        // console.log(book);

        var splits = book.lesson_time.split(":");
        if (splits[1] == "00") {
          schedule[book.lesson_time].openOnTime =
            Constants.scheduleState.Booked;
          if (book.lesson_type !== Constants.lessonType.Thirty) {
            schedule[book.lesson_time].openHalf =
              Constants.scheduleState.Booked;
          }
        } else {
          schedule[`${splits[0]}:00`].openHalf = Constants.scheduleState.Booked;
          if (book.lesson_type !== Constants.lessonType.Thirty) {
            schedule[`${this.getNextHourString(splits[0])}:00`].openOnTime =
              Constants.scheduleState.Booked;
          }
        }
      });
    } else {
      alert("스케쥴 정보를 가져올 수 없습니다");
      return;
    }

    // if (
    //   _branch !== "" &&
    //   this.state._teacher !== "" &&
    //   schedule[this.state._teacher]
    // ) {
    //   const lessonTimes = document.getElementsByName("lesson_time");

    //   lessonTimes.forEach((item, index) => {
    //     if (
    //       schedule[this.state._teacher] &&
    //       schedule[this.state._teacher][item.value] != scheduleState.Close
    //     ) {
    //       item.checked = false;
    //       lessTime = false;
    //     } else {
    //       item.disabled = false;
    //     }
    //   });
    // }

    // console.log(schedules);
    const availableTeacherList = this.getAvailableTeacherList(
      teacherList,
      schedules,
      dow,
      lesson_at
    );
    this.setState((state) => ({
      lesson_at,
      dayOfWeek: dow,
      dowNum: dowNum,
      // unable_schedule: schedule,
      schedule: schedules,
      lesson_time: lessTime ? state.lesson_time : "",
      availableTeacherList: availableTeacherList,
    }));

    // let end = new Date();  // 종료

    // console.log(end - start); // 경과시간(밀리초)
  }

  getNextHourString(hour) {
    return ("00" + (Number(hour) + 1).toString()).slice(-2);
  }

  componentDidMount = async () => {
    try {
      var now = moment();
      const dow = await this.setDayOfWeek(Date.now());

      const ticketRes = await TicketClient.getList({
        _user: this.state._user,
      });

      const tickets = ticketRes.data.success ? ticketRes.data.result : [];
      //   console.log(tickets);

      const _branch =
        0 < tickets.length ? tickets[0]._branch : this.state._branch;

      this.setState(
        {
          _branch: _branch,
          dayOfWeek: dow,
          dowNum: moment(Date.now()).add(1, "days").day(),
          tickets: tickets,
        },
        () => {
          this.loadBranch();
        }
      );
    } catch (error) {
      this.setState({ errorMsg: "Error retreiving data" });
    }
  };

  loadBranch = async () => {
    try {
      const teacherRes = await AdminClient.getList({
        _branch: this.state._branch,
        _subject: "all",
        role: "강사",
      });

      if (teacherRes.data.success) {
        teacherRes.data.result.sort((a, b) => {
          if (teacherOrder[a._subject.name] < teacherOrder[b._subject.name])
            return -1;
          else if (
            teacherOrder[a._subject.name] > teacherOrder[b._subject.name]
          )
            return 1;
          else return 0;
        });
      }

      const eventRes = await EventClient.getList({
        _branch: this.state._branch,
      });

      const holidayRes = await HolidayClient.getList({
        _branch: this.state._branch,
      });

      this.setState(
        {
          teacherArr: teacherRes.data.success ? teacherRes.data.result : [],
          events: eventRes.data.success ? eventRes.data.result : [],
          holidays: holidayRes.data.success ? holidayRes.data.result : [],
        },
        () => {
          this.fetchData(
            this.state.lesson_at,
            this.state._branch,
            this.state.teacherArr
          );
        }
      );
    } catch (error) {
      alert(error.message);
    }
  };

  onChange = async (e) => {
    if (e.target.name === "_teacher") {
      this.setState((state) => {
        return {
          [e.target.name]: e.target.value,
          lesson_time: "",
        };
      });
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          if (e.target.name == "_branch") {
            this.loadBranch();
          }
        }
      );
    }
  };

  handleDateClick = async (lesson_at) => {
    try {
      if (this.state._branch == "" || this.state.teacherArr.length == 0) {
        this.setState({
          lesson_at: lesson_at,
        });
        return;
      }
      await this.fetchData(
        lesson_at,
        this.state._branch,
        this.state.teacherArr
      );
    } catch (error) {
      console.log(error);
      alert("데이터 조회 실패");
    }
  };

  onSubmit = (e) => {
    this.setState({});
    e.preventDefault();

    if (
      !this.state._branch ||
      !this.state._teacher ||
      !this.state.lesson_time ||
      !this.state.lesson_at ||
      !this.state._user
    ) {
      alert("모든 입련란에 입력해주세요.");
    } else {
      const lesson_at = moment(this.state.lesson_at).format("YYYY-MM-DD");

      const teacher = this.state.teacherArr.find(
        (item) => item._id == this.state._teacher
      );

      const tickets = this.state.tickets.filter((item) => {
        // console.log(moment(item.startDate).format('YYYY-MM-DD'), lesson_at, moment(item.endDate).format('YYYY-MM-DD'));
        return (
          moment(item.startDate).format("YYYY-MM-DD") <= lesson_at &&
          lesson_at <= moment(item.endDate).format("YYYY-MM-DD")
        );
      });

      if (tickets.length == 0) {
        alert("보유 중인 수강권이 존재하지 않습니다.");
        return;
      }

      const ticket = tickets[0];

      let lessonType = Constants.lessonType.Fifty;
      const lessonPlan = ticket.lessonPlans.find(plan => plan._id === String(teacher._subject._id));
      if (lessonPlan) {
        lessonType = lessonPlan.value;
      }

      const splits = this.state.lesson_time.split(":");
      const hour = splits[0];
      const minutes = splits[1];

      const teacher_schedule = this.state.schedule[this.state._teacher];
      const schedule = teacher_schedule[`${hour}:00`];

      // console.log(teacher_schedule);

      if (lessonType == Constants.lessonType.Fifty) {
        if (minutes == "00") {
          if (
            schedule.allowHalfTime &&
            schedule.openHalf != Constants.scheduleState.Open
          ) {
            if (
              window.confirm(
                "※주의※ 해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
            ) {
              return;
            }
          }
        } else {
          const nextSchedule =
            teacher_schedule[`${this.getNextHourString(hour)}:00`];
          if (
            !nextSchedule.allowHalfTime ||
            nextSchedule.openOnTime != Constants.scheduleState.Open
          ) {
            if (
              window.confirm(
                "※주의※ 해당 시간에 예약시 30분 수업으로 진행됩니다. 예약하시겠습니까?"
              ) === false
            ) {
              return;
            }
          }
        }
      } else {
        if(!schedule.allowHalfTime) {
          const confirm = window.confirm("※주의※ 해당 시간에 예약시 60분 수업으로 진행되며 레슨 횟수가 2회 소모됩니다. 예약하시겠습니까?");
          if (confirm === false) {
            return;
          }
        }
      }

      if (!window.confirm("정말 예약하시겠습니까?")) return;

      this.book(
        this.state._branch,
        teacher._subject,
        this.state._teacher,
        this.state.lesson_time,
        lesson_at,
        this.state._user
      );
    }
  };
  book = async (_branch, _subject, _teacher, lesson_time, lesson_at, _user) => {
    try {
      const res = await BookClient.create(this.context._id, {
        _branch,
        _subject,
        _teacher,
        lesson_time,
        lesson_at,
        _user,
      });

      if (res.data.success) {
        alert(res.data.message);
        this.props.history.push("/");
      } else {
        alert(res.data.message);
      }
    } catch (err) {
      alert("데이터 조회 실패");
    }
  };

  isSixtyLesson = (lessonType) => {
    const teacher_schedule = this.state.schedule[this.state._teacher];
    if(!teacher_schedule) return  false;

    const splits = this.state.lesson_time.split(":");
    const hour = splits[0];

    const schedule = teacher_schedule[`${hour}:00`];
    if(!schedule) return false;

    if (lessonType !== Constants.lessonType.Fifty)  {
      if(!schedule.allowHalfTime) {
        return true;
      }
    }
    return false;
  }

  setDisableRadio = (time) => {
    if (this.isHoliday(moment(this.state.lesson_at))) {
      // console.log(params + true);
      return true;
    }

    const splits = time.split(":");

    const schedule = this.state.schedule[this.state._teacher];
    // console.log(this.state.schedule, this.state._teacher);

    // console.log(time, schedule);
    if (!schedule) return true;
    // console.log(this.state._teacher, schedule);
    if (splits[1] == "00") {
      // console.log(time, schedule[`${splits[0]}:00`].openOnTime);
      return (
        schedule[`${splits[0]}:00`].openOnTime != Constants.scheduleState.Open
      );
    } else {
      // console.log(time, schedule[`${splits[0]}:00`].openHalf);
      return (
        schedule[`${splits[0]}:00`].openHalf != Constants.scheduleState.Open
      );
    }

    // if (!this.state.schedule[this.state._teacher]) return true;
    // console.log(this.state.unable_schedule);
    // console.log(params + " " +this.state._teacher + " %o", this.state.unable_schedule.filter((item) => item.teacher === this.state._teacher && item.time === params));
    // console.log(this.state.schedule[this.state._teacher], params);
    // return (
    // this.state.schedule[this.state._teacher][params] != scheduleState.Open
    // );
  };

  isHoliday(date) {
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    var holiday = this.state.holidays.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    return holiday !== undefined;
  }

  radioChange = (e) => {
    if (this.setDisableRadio(e.target.value)) {
      return;
    }

    this.setState({
      lesson_time: e.target.value,
    });
  };

  getTileClassName(arg) {
    if (arg.view !== "month") return null;

    var date = moment(arg.date);
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    var holiday = this.state.holidays.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    if (holiday) return "tile_content_sun";

    var dow = date.day();
    if (dow === 0) return "tile_content_sun";
    else if (dow === 6) return "tile_content_sat";

    if (
      moment(this.state.lesson_at).format("YYYY-MM-DD") ===
      moment(arg.date).format("YYYY-MM-DD")
    )
      return "tile_content_selected";

    if (moment(arg.activeStartDate).format("MM") != date.format("MM"))
      return "tile_content_gray";

    return "tile_content";
  }

  getTileContent(arg) {
    if (arg.view !== "month") return null;

    var date = moment(arg.date);

    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    var event = this.state.events.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    var holiday = this.state.holidays.find(function (item) {
      return item.year === year && item.month === month && item.day === day;
    });
    return (
      <div>
        {holiday ? <div className="tile_content_sun"> 휴일 </div> : null}
        {event ? <a className="tile_content_event"></a> : null}
      </div>
    );
  }

  renderEvents() {
    var date = moment(this.state.lesson_at);
    var year = date.year();
    var month = date.month() + 1;
    var day = date.date();
    const events = this.state.events.filter(
      (item) => item.year === year && item.month === month && item.day === day
    );
    return events.map((item, index) => {
      return (
        <div className="tile_content_event_text" key={index}>
          {item.name}
        </div>
      );
    });
  }

  _renderButton(time, index) {
    const disabled = this.setDisableRadio(time);
    return (
      <label key={index} className="box-radio-input">
        <input
          type="radio"
          name="lesson_time"
          value={time}
          disabled={disabled}
          checked={time === this.state.lesson_time && !disabled}
          onChange={this.radioChange}
        />
        <span className={disabled ? "bg-secondary" : ""}>{time}</span>
      </label>
    );
  }

  renderButton(time, index) {
    const splits = time.split(":");
    const hour = splits[0];
    const minutes = splits[1];

    const schedule = this.state.schedule[this.state._teacher];

    return (
      <>
        {this._renderButton(time, index)}
        {schedule && schedule[time].allowHalfTime
          ? this._renderButton(`${hour}:30`)
          : null}
      </>
    );
  }

  render() {
    var tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const isHoliday = this.isHoliday(moment(this.state.lesson_at));
    const lesson_at = moment(this.state.lesson_at).format("YYYY-MM-DD");
    const tickets = this.state.tickets.filter((item) => {
      // console.log(moment(item.startDate).format('YYYY-MM-DD'), lesson_at, moment(item.endDate).format('YYYY-MM-DD'));
      return (
        moment(item.startDate).format("YYYY-MM-DD") <= lesson_at &&
        lesson_at <= moment(item.endDate).format("YYYY-MM-DD")
      );
    });

    const teacher = this.state.teacherArr.find(
        (item) => item._id == this.state._teacher
    );
    const ticket = tickets.length > 0 ? tickets[0] : null;

    let lessonType = Constants.lessonType.Fifty;
    if(ticket && teacher) {
      const lessonPlan = ticket.lessonPlans.find(plan => plan._id === String(teacher._subject._id));
      if (lessonPlan) {
        lessonType = lessonPlan.value;
      }
    }

    // console.log(tickets);
    return (
      <form className="form m-10" id="contact-form" onSubmit={this.onSubmit}>
        <div className="col-md-6 fl">
          <section className="mw-500 ma-10 mb-30">
            <div id="calendiv" className="w-100 mt-30"></div>
            <Calendar1
              calendarType="US"
              onChange={this.handleDateClick}
              value={this.state.lesson_at}
              name="lesson_at"
              minDate={this.state.today}
              tileClassName={(e) => this.getTileClassName(e)}
              tileContent={(e) => this.getTileContent(e)}
            />
          </section>
          <div>{this.renderEvents()}</div>
        </div>
        <div className="col-md-6 fl mt-20">
          {tickets.length > 1 ? (
            <div className="col-md-12">
              <div className="form-group row text-left">
                <p className="col-sm-2 col-form-label mt-5 pl-25">
                  지점&nbsp;<span className="colorred">*</span>
                </p>
                <div className="col-sm-10 input-group text-center mt-5">
                  <select
                    className="custom-select"
                    id="form_message"
                    name="_branch"
                    onChange={this.onChange}
                    value={this.state._branch}
                  >
                    {tickets.map((item, index) => {
                      return (
                        <option key={index} value={item._branch}>
                          {item.branch.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-md-12">
            <div className="form-group row text-left">
              <p className="col-sm-2 col-form-label mt-5 pl-25">
                강사&nbsp;<span className="colorred">*</span>
              </p>
              <div className="col-sm-10 input-group text-center mt-5">
                {this.state.availableTeacherList.length > 0 ? (
                  this.state.availableTeacherList.map((item, index) => {
                    return (
                      <label key={index} className="box-radio-input">
                        <input
                          type="radio"
                          name="_teacher"
                          value={item._id}
                          checked={item._id === this.state._teacher}
                          onChange={this.onChange}
                        />

                        <span>{item.name}</span>
                      </label>
                    );
                  })
                ) : (
                  <label>수강 신청 가능 한 강사가 없습니다.</label>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group row text-left">
              <p className="col-sm-2 col-form-label mt-5 pl-25">
                오후&nbsp;<span className="colorred">*</span>
              </p>
              <div className="col-sm-10 mt-5 input-group text-center">
                {this.state.times.map((item, index) => {
                  return this.renderButton(item, index);
                })}
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-0">
            <div className="form-group">
              <hr className="hrbook"/>
              <p>
                레슨일시{" "}
                {moment(this.state.lesson_at).format("YYYY년 MM월 DD일")}{" "}
                {this.state.lesson_time}시
              </p>
              {isHoliday ? (
                <p className="bookcheck">휴일은 예약 불가합니다.</p>
              ) : null}
              {
                ticket && this.isSixtyLesson(lessonType) ? (
                  <p className="bookcheck">*주의! 해당 예약은 레슨횟수 2회를 소진하며, 60분으로 진행됩니다.</p>
                ) : null
              }
              <p>[ 예약 및 변경 안내사항 ]</p>
              <p>- 상시예약 : 레슨일 하루 전 22시까지</p>
              <p>- 당일예약 : 필요 시에 학원으로 문의</p>
              <p>- 예약취소 : 레슨일 하루 전 20시까지</p>
            </div>
          </div>
          <div className="col-md-12 text-center mt-0">
            <button
              disabled={isHoliday || this.state.isSubmitting}
              type="submit"
              className="btn btn-lg"
            >
              예약하기
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(FormBook);
