import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import UserClient from "../utils/Http/UserClient";

class FormIdSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            // phone: '',
            email: "",
        };
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const idsearch = {
            name: this.state.name,
            // phone: this.state.phone,
            email: this.state.email,
        };

        try
        {
            const res = await UserClient.searchId(idsearch);

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            <form
                className="form m-10"
                id="contact-form"
                onSubmit={this.onSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>NAME</p>
                            <input
                                id="form_subject"
                                type="text"
                                name="name"
                                placeholder="이름을 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                        {/* <div className="form-group text-left">
                        <p>PHONE</p>
                        <input id="form_subject" type="text" name="phone" placeholder="연락처를 입력하세요" required="required" onChange={this.onChange} />
                    </div> */}
                        <div className="form-group text-left">
                            <p>EMAIL</p>
                            <input
                                id="form_subject"
                                type="text"
                                name="email"
                                placeholder="이메일을 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-0">
                        <p>등록된 이메일로 아이디를 전송합니다.</p>
                    </div>
                    <div className="col-md-12 text-center mt-15">
                        <button type="submit" className="btn btn-lg">
                            확인
                        </button>
                    </div>
                    <div className="col-md-12 text-right mt-10">
                        <p>
                            <Link to="/passwordsearch">FORGOT PASSWORD</Link>
                        </p>
                    </div>
                </div>
            </form>
        );
    }
}

export default withRouter(FormIdSearch);
