import React, { Component } from "react";
// import { withRouter } from 'react-router-dom';
import moment from "moment";
// import jwt_decode from "jwt-decode";
import { AuthContext } from "../components/AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import TicketClient from "../utils/Http/TicketClient";

import "../App.css";

class TableTicket extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // context 사용해서 로그인한 사용자 정보 가져오기
        this.state = {
            tickets: [],
        };
    }

    componentDidMount = async () => {
        try
        {
            const res = await TicketClient.getList({
                _user: this.context._id,
            });

            if(res.data.result)
            {
                res.data.result.sort((a, b) =>{
                    if(a.start_date < b.start_date)
                        return -1;
                    return 1;
                });
                const today = moment().format("YYYY-MM-DD");
                res.data.result.forEach((item) => {
                    item.holdings = item.holdings.filter((holding) => holding.delete === false && today <= moment(holding.endDate).format("YYYY-MM-DD"))
                    item.holdings.sort((a, b) => {
                        if(a.startDate < b.startDate)
                            return -1;
                        return 1;
                    });

                    item.books = item.books.filter((book) => book.status === 'apply' && today <= moment(book.lesson_at).format("YYYY-MM-DD"))
                    item.books.sort((a, b) => {
                        if (a.lesson_at == b.lesson_at) {
                            if (a.lesson_time > b.lesson_time) {
                                return 1;
                            } else return -1;
                        }

                        if (a.lesson_at > b.lesson_at) {
                            return 1;
                        } else return -1;
                    });
                });

            }
            this.setState({ tickets: res.data.result });
        }
        catch(err)
        {
            console.log(err);
            this.setState({ errorMsg: "Error retreving data" });
        }
    }

    holding_date(holding)
    {
        const start = moment(holding.startDate).format("YYYY-MM-DD");
        const end = moment(holding.endDate).format("YYYY-MM-DD");

        if(start === end)
        {
            return start;
        }

        return start + ' - ' + end;
    }

    render_lesson_holding(ticket)
    {
        return(
            <div className="table-responsive text-nowrap">
                <div className="card card-bordered">
                    <div className="card-inner">
                        <table className="table-holding">
                            <thead>
                                <tr>
                                    <th style={{width: "50%"}}>레슨</th>
                                    <th style={{width: "50%"}}>홀딩</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{ticket.books.length > 0 ? moment(ticket.books[0].lesson_at).format("YYYY-MM-DD") : ''}</td>
                                    <td>{ticket.holdings.length > 0 ? this.holding_date(ticket.holdings[0]) : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    render_ticket(ticket)
    {
        return (
            <>
            <div className="nk-block">
                <div className="card card-bordered">
                    <div className="card-inner">
                        <div className="table-responsive text-nowrap w-100 d-block d-md-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{width: "20%"}}>이름</th>
                                        <th style={{width: "20%"}}>지점</th>
                                        <th style={{width: "20%"}}>기간</th>
                                        <th style={{width: "20%"}}>잔여레슨</th>
                                        <th style={{width: "20%"}}>잔여홀딩</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ticket.name}</td>
                                        <td>{ticket.branch ? ticket.branch.name : ""} </td>
                                        <td>{moment(ticket.startDate).format("YYYY-MM-DD")} - {moment(ticket.endDate).format("YYYY-MM-DD")}</td>
                                        <td>{ticket.lesson + " 회"}</td>
                                        <td>{ticket.holding + " 일"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {this.render_lesson_holding(ticket)}
                    </div>
                </div>
            </div>
            <div className="divider" />
            </>
        );
    }

    render() {

        return (
            <div>
                {
                    this.state.tickets.map(
                        (ticket) => {
                            return this.render_ticket(ticket);
                        }
                    )
                }
            </div>

        );

        // return (
        //     <div>
        //             <div className="table-responsive text-nowrap">
        //             <table className="table">
        //                 <thead>
        //                     <tr>
        //                         <th>이름</th>
        //                         <th>지점</th>
        //                         <th>시작일</th>
        //                         <th>종료일</th>
        //                         <th>잔여 레슨</th>
        //                         <th>잔여 홀딩</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {this.state.tickets.map(
        //                         (ticket) => {
        //                             return (
        //                                 <tr>
        //                                     <td>{ticket.name}</td>
        //                                     <td>{ticket._branch ? ticket._branch.name : ""} </td>
        //                                     <td>{moment(ticket.startDate).format("YYYY-MM-DD")}</td>
        //                                     <td>{moment(ticket.endDate).format("YYYY-MM-DD")}</td>
        //                                     <td>{ticket.lesson}</td>
        //                                     <td>{ticket.holding}</td>
        //                                 </tr>
        //                             );
        //                         }
        //                     )}
        //                 </tbody>
        //             </table>
        //         </div>



        //     </div>
        // );
    }
}

export default TableTicket;
