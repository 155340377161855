import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FormAgree from "../components/FormAgree";

class SignupAgree extends Component {
    render() {
        return (
            <section className="about pb-100">
                <div className="container pt-20" id="mw-450">
                    <div className="row m-10 text-center">
                        <div className="col-md-12 text-center">
                            <div className="container mb-30">
                                <h6>약관동의</h6>
                            </div>
                            <FormAgree />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(SignupAgree);
