import React, { Component } from "react";
import { Link } from "react-router-dom";
import mlblogo from "../img/mlblogo.png";
import jwt_decode from "jwt-decode";
import UserClient from "../utils/Http/UserClient";
import PointClient from "../utils/Http/PointClient";

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kakao_connect: false,
      menuOpen: false,
      point: 0,
    };

    this.setMenuToggle = this.setMenuToggle.bind(this);
  }

  setMenuToggle = () => {
    this.setState((state) => ({
      menuOpen: !state.menuOpen,
    }));
  };

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      const response = await UserClient.get(decoded._id);
      this.setState({
        menuOpen: false,
        kakao_connect:
          response.data.result.kakao_connect !== undefined
            ? response.data.result.kakao_connect
            : false,
        point: response.data.result.points[response.data.result._branch._id] ?? 0
      });
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  getPoint() {
    return 0;
  }

  render() {
    let menuOpenStyle;
    let toggleBurgerMenu;
    if (this.state.menuOpen) {
      menuOpenStyle = "toggled";
      toggleBurgerMenu = "togo-burger-menu togo-menu-open";
    } else {
      menuOpenStyle = "";
      toggleBurgerMenu = "togo-burger-menu";
    }

    return (
      <>
        <div className={menuOpenStyle} id="sidebar-wrapper">
          <nav className="list-group">
            <div style={{ padding: "30px" }}>
              {/* <div className="d-none d-sm-none d-md-block">
                            <Link to='/'><img style={{
                                width: '100%',
                                marginBottom: '30px'
                            }} src={mlblogo} alt="" id="logoimage"/></Link>
                            </div> */}
            </div>
            {newComponent(this.setMenuToggle, true, this.state.kakao_connect, this.state.point)}
          </nav>
          <div
            className={toggleBurgerMenu}
            id="menu-toggle"
            onClick={this.setMenuToggle}
          >
            <span>
              <div
                className={`togo-line-menu togo-line-half togo-first-line ${
                  this.props.hasBanner ? "bg-color-white" : ""
                }`}
              ></div>
              <div
                className={`togo-line-menu ${
                  this.props.hasBanner ? "bg-color-white" : ""
                }`}
              ></div>
              <div
                className={`togo-line-menu togo-line-half togo-last-line ${
                  this.props.hasBanner ? "bg-color-white" : ""
                }`}
              ></div>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export const newComponent = (setMenuToggle, check, kakao_connect, point) => {
  const token = localStorage.getItem("token");

  if (token == null) {
    return (
      <li>
        <div className="btn-group">
          <Link to="/signupagree">
            <p className="sideli">SIGN UP</p>
          </Link>
          <Link to="/login">
            <p className="sideli">LOGIN</p>
          </Link>
        </div>
      </li>
    );
  } else {
    const decoded = jwt_decode(token);

    function CheckOut() {
      alert("로그아웃 하시겠습니까?");
    }

    async function KakaoConnect() {
      const res = await UserClient.getKakaoLogin();
      if (res.data.success) {
        window.location.href = res.data.result.url;
      }
    }

    async function KakaoDisconnect() {
      if (window.confirm("카카오 연동 해제 하시겠습니까?") == false) {
        return;
      }

      const res = await UserClient.kakaoDisConnect(decoded._id);
      if (res.data.success) {
        alert("카카오 연동 해제 완료되었습니다.");
        window.location.href = "/";
      } else {
        alert(res.data.message);
      }
    }

    return (
        <>
      <div className="text-center">
        <p
          className="username"
          style={{
            marginBottom: "10px",
          }}
        >
          <span className="headername">
            <Link to={"/usermodify/" + decoded._id}>{decoded.name}</Link>
          </span>
          님 반갑습니다.
        </p>
        <p style={{textDecoration: "underline"}}>
          ☆Point. {point.toLocaleString("en-US")}
        </p>
        <p className="username">
          <Link to={"/ticketlist"}>수강권</Link>
        </p>
        <p className="username">
          <Link to={"/booklist"}>나의 예약 내역</Link>
        </p>
        <p className="username">
          <Link to="/book">예약하기</Link>
        </p>
        <p className="username">
          <Link to="/takelessonlist">수강 내역</Link>
        </p>
        {/*<p className="username">*/}
        {/*  <Link to="/coupon">쿠폰함</Link>*/}
        {/*</p>*/}
        {/*<p className="username">*/}
        {/*  <Link to={"/point"}>적립금</Link>*/}
        {/*</p>*/}
        <p className="username">
          <Link to={"/suggestion"}>홈페이지 개선 요청사항</Link>
        </p>
        <p
          className="username"
          style={{ cursor: "pointer" }}
          onClick={kakao_connect === false ? KakaoConnect : KakaoDisconnect}
        >
          {kakao_connect === false ? "카카오 연결하기" : "카카오 연결 해제"}
        </p>
        <p className="username">
          <Link to="/logout" onClick={CheckOut}>
            LOGOUT
          </Link>
        </p>
      </div>
        <div >
          <div className="row">
            <div className="col">
              <button
                  className="btn btn-sm"
                  style={{ background:"white", borderColor: "black", color: "black", fontWeight: "normal", top: "30px", left: "60px"}}
                  onClick={() => { window.location.href = "/coupon"; }}
              >
                쿠폰함
              </button>
              <button
                  className="btn btn-sm"
                  style={{ background:"white", borderColor: "black", color: "black", fontWeight: "normal", top: "30px", left: "80px"}}
                  onClick={() => { window.location.href = "/point"; }}
              >
                적립금
              </button>
            </div>
          </div>
        </div>
        </>
    );
  }
};

export default Aside;
