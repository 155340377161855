import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import UserClient from "../utils/Http/UserClient";

class FormReset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // id: '',
            // password: '',
            password1: "",
            password2: "",
        };
    }

    componentDidMount = async () => {
        try {
            const response = await UserClient.get(this.props.match.params.id);

            this.setState({
                id: response.data.result.id,
                // password: response.data.result.password,
            });
        } catch (err) {
            console.log(err);
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const reset = {
            id: this.state.id,
            // password: this.state.password,
            password1: this.state.password1,
            password2: this.state.password2,
        };

        try
        {
            const res = await UserClient.reset(this.props.match.params.id, reset);

            if (res.data.success === true) {
                alert(res.data.message);
                this.props.history.push(`/`);
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            alert(err.message + "실패");
        }
    };

    render() {
        return (
            <form
                className="form m-10"
                id="contact-form"
                onSubmit={this.onSubmit}
            >
                <div className="row">
                    {/* <div class="col-md-12">
                    <div class="form-group text-left">
                        <p>NOW PASSWORD&nbsp;<span class="colorred">*</span></p>
                        <input id="form_message" type="hidden" name="password" placeholder="현재 비밀번호를 입력하세요" rows="4" value={this.state.password}/>
                    </div>
                </div> */}
                    {/* <input id="form_message" type="hidden" name="id" placeholder="아이디를 입력하세요" rows="4" value={this.state.id}  onChange={this.onChange}/> */}
                    {/* <input id="form_message" type="hidden" name="password" placeholder="현재 비밀번호를 입력하세요" rows="4" value={this.state.password}  onChange={this.onChange}/> */}
                    <div class="col-md-12">
                        <div class="form-group text-left">
                            <p>
                                NEW PASSWORD&nbsp;
                                <span class="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password1"
                                placeholder="새 비밀번호를 입력하세요"
                                rows="4"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group text-left">
                            <p>
                                NEW PASSWORD CHECK&nbsp;
                                <span class="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password2"
                                placeholder="새 비밀번호를 확인합니다"
                                rows="4"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-15">
                        <Link to="/">
                            <button type="button" className="btn btn-lg m-1">
                                취소하기
                            </button>
                        </Link>
                        <button type="submit" className="btn btn-lg m-1">
                            수정하기
                        </button>
                    </div>
                </div>
                {/* <div class="text-center mt-50">
                <button class="btn btn-sm" type="button">탈퇴하기</button>
            </div> */}
            </form>
        );
    }
}

export default withRouter(FormReset);
