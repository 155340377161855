import AxiosClient from "./AxiosClient";

const ScheduleClient = {
  get: async (query) => {
    return await AxiosClient.get(`schedule/list/${query}`);
  },

  getList: async (data) => {
    return await AxiosClient.get("schedule/list", data);
  },
  getScheduleAll: async (data) => {
    return await AxiosClient.get(`schedule/branch`, data);
  },
  update: async (id, data) => {
    return await AxiosClient.get(`schedule/update${id}`, data);
  },

  createTemp: async (data) => {
    return await AxiosClient.post("schedule/temp", data);
  },

  deleteTemp: async (data) => {
    return await AxiosClient.patch("schedule/temp/delete", data);
  },
};

export default ScheduleClient;
