// 영어 숫자
// var engNum =  /^[a-zA-Z0-9]*$/;
var engNum = /^(?=.*[A-Za-z0-9])[A-Za-z0-9.,+=_-]+$/;

const messages = {
  startDateErrMessage: '시작 날짜는 종료 날짜보다 전날이여야 합니다.',
  defaultMessage: (target) => `${target} 양식을 입력하세요.`,
};

const result = (result, message) => {
  return {
    success: result,
    message: message,
  };
};

const Validation = {
  startDate: (value, compare) => {
    if (value < compare) {
      return result(true);
    } else {
      return result(false, messages.startDateErrMessage);
    }
  },
  endDate: (value, compare) => {
    if (value > compare) {
      return result(true);
    } else {
      return result(false, messages.startDateErrMessage);
    }
  },
  id: (value) => {
    console.log(value);
    if (!engNum.test(value)) {
      console.log(engNum.test(value));
      return result(false, messages.defaultMessage('아이디는 영문/숫자'));
    } else {
      return result(true);
    }
  },
};

export default Validation;
