import React, { Component } from "react";
// import { withRouter } from 'react-router-dom';
import moment from "moment";
// import jwt_decode from "jwt-decode";
import { AuthContext } from "./AuthenticatedComponent";
import ReactPaginate from "react-paginate";
import BookClient from "../utils/Http/BookClient";

class TableTakeLesson extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        // context 사용해서 로그인한 사용자 정보 가져오기
        this.state = {
            // lesson_at: '',
            // lesson_time: '',
            // _user: ''
            books: [],
            errorMsg: "",
            offset: 0,
            // tableData: [],
            orgtableData: [],
            perPage: 5,
            currentPage: 0,
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.loadMoreData();
            }
        );
    };

    loadMoreData() {
        const data = this.state.orgtableData;

        const slice = data.slice(
            this.state.offset,
            this.state.offset + this.state.perPage
        );
        this.setState({
            pageCount: Math.ceil(data.length / this.state.perPage),
            books: slice,
        });
    }

    componentDidMount = async () => {
        try
        {
            const res = await BookClient.getTakeLessons({
                _user: this.context._id,
            });

            if (res.data.success) {
                var data = res.data.result;
                var slice = data.slice(
                    this.state.offset,
                    this.state.offset + this.state.perPage
                );

                this.setState({
                    // books: res.data.result,

                    pageCount: Math.ceil(data.length / this.state.perPage),
                    orgtableData: res.data.result,
                    books: slice,
                });
            } else {
                alert(res.data.message);
            }
        }
        catch(err)
        {
            console.log(err);
            this.setState({ errorMsg: "Error retreiving data" });
        }
    }

    setDayOfWeek = (date) => {
        const dow = moment(date).day();
        let day = "";
        if (dow === 0) day = "일";
        if (dow === 1) day = "월";
        if (dow === 2) day = "화";
        if (dow === 3) day = "수";
        if (dow === 4) day = "목";
        if (dow === 5) day = "금";
        if (dow === 6) day = "토";

        return day;
    };

    render_bookStatus(book) {
        switch (book.status) {
            case "apply":
                return "수강 완료";
            case "cancelOnTheDay":
                return "당일 취소";
            default:
                return "";
        }
    }

    render() {
        const { books, errorMsg } = this.state;

        const token = localStorage.getItem("token");

        return (
            <div>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        {/* <colgroup>
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="50%" />
                            <col width="10%" />
                        </colgroup> */}
                        <thead>
                            <tr>
                                <th>예약일</th>
                                <th>예약시간</th>
                                <th>강사</th>
                                <th>과목</th>
                                {/* <th>수강내역</th> */}
                                {/* <th>남은/총 레슨횟수</th> */}
                                {/* <th>내용</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {books.length
                                ? books.map(
                                      (book, index) => (
                                          // book._user === decoded._id ?
                                        <>
                                            <tr key={index}>
                                                <td rowSpan={2}>
                                                    {moment(
                                                        book.lesson_at
                                                    ).format("YY.MM.DD")}{" "}
                                                    (
                                                    {this.setDayOfWeek(
                                                        book.lesson_at
                                                    )}
                                                    )
                                                </td>
                                                <td>{book.lesson_time}</td>
                                                <td>
                                                    {book._teacher
                                                        ? book._teacher.name
                                                        : book.teacher_name}
                                                </td>
                                                <td>
                                                    {book._subject
                                                        ? book._subject.name
                                                        : ""}
                                                </td>
                                                {/* <th>{book._user.lesson_number} / {book._user.lesson_number_all}</th> */}
                                                {/* <td>
                                                    {book.comment}
                                                </td> */}
                                                <td>
                                                    {this.render_bookStatus(book)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="display-enter break-all" colSpan={4} align="left"> {book.comment} </td>
                                            </tr>
                                        </>
                                      )
                                      // : null
                                  )
                                : null}
                            {errorMsg ? <div>{errorMsg}</div> : null}
                        </tbody>
                    </table>
                </div>

                <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            </div>
        );
    }
}

export default TableTakeLesson;
