import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FormSign from "../components/FormSign";

class SignupAgree extends Component {
    render() {
        return (
            <section className="about pb-100">
                <div className="container pt-20" id="mw-400">
                    <div className="row m-10 text-center">
                        <div className="col-md-12 text-center">
                            <div className="container mb-30">
                                <h6>SIGN UP</h6>
                            </div>
                            <FormSign />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(SignupAgree);
