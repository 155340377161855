import React, { Component } from "react";
import Aside from "../components/Aside";
import jwt_decode from "jwt-decode";
import TableTicket from "../components/TableTicket";
import NoticePopup from "../components/NoticePopup";
import UserClient from "../utils/Http/UserClient";
import BranchClient from "../utils/Http/BranchClient";

class TicketList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: "",
    };
  }

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      console.log(decoded);
      const response = await UserClient.get(decoded._id);
      if (!response.data.success) {
        alert("계정 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.loadBanner(response.data.result._branch._id);
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  loadBanner = async (_branch) => {
    try {
      const response = await BranchClient.getBanner(_branch);

      // const test = `<b style='color:white !important'>비밀번호 0000</b>\n연주회 5/1\n상담가능시간 14:00~22:00`;
      // console.log(response.data.result.banner);
      this.setState({
        banner:
          response.data.success && response.data.result.banner
            ? response.data.result.banner
            : "",
      });
    } catch (err) {
      console.log(err);
      alert("지점 정보를 불러올 수 없습니다.");
    }
  };

  render() {
    return (
      <>
        <NoticePopup></NoticePopup>
        <div className="d-flex" id="wrapper">
          <Aside hasBanner={this.state.banner !== "" ? true : false} />

          <div className="togo-side-content" style={{ width: "100%" }}>
            {this.state.banner == "" ? null : (
              <div className="banner display-enter">
                <div
                  className="banner-text"
                  style={{ color: "white" }}
                  dangerouslySetInnerHTML={{ __html: this.state.banner }}
                />
              </div>
            )}

            {/* <div className="text-center header-logo">
                <Link to="/">
                <img
                    style={{
                    maxWidth: 240,
                    marginBottom: "30px",
                    padding: "5px 10px",
                    }}
                    src={mlblogo}
                    alt=""
                    id="logoimage"
                />
                </Link>
            </div> */}
            <div className="container">
              <div className="pt-70"></div>
              <section className="about pb-100">
                <div className="container pt-20" id="mw-880">
                  <div className="row m-10 text-center">
                    <div className="col-md-12 text-center">
                      <div className="container mb-40">
                        <h6 className="">수강권</h6>
                      </div>
                      <TableTicket />
                      <div className="col-md-12 text-center mt-0">
                        <button
                          type="submit"
                          className="btn btn-lg"
                          style={{ "margin-right": 10, "z-index": 0 }}
                          onClick={() => {
                            this.props.history.push("/booklist");
                          }}
                        >
                          예약내역
                        </button>
                        <button
                          type="submit"
                          className="btn btn-lg"
                          style={{ "z-index": 0 }}
                          onClick={() => {
                            this.props.history.push("/book");
                          }}
                        >
                          예약하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TicketList;
