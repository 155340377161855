import AxiosClient from "./AxiosClient";

const SuggestionClient = {
  create: async (id, content) => {
    return await AxiosClient.post(`suggestion/`, {
      _user: id,
      content: content,
    });
  },
};

export default SuggestionClient;
