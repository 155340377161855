import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import DateDate from './DateDate';
import moment from "moment";
import Validation from "../utils/Validations";
import BranchClient from "../utils/Http/BranchClient";
import UserClient from "../utils/Http/UserClient";

class FromSign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            email: "",
            password: "",
            password2: "",
            name: "",
            phone: "",
            gender: "",
            birth_year: "",
            birth_month: "",
            birth_day: "",
            _branch: "",
            branchArr: [],
            errorMsg: "",
            create_at: Date.now(),
            updte_at: Date.now(),
        };
    }

    componentDidMount = async () => {
        try {
            const branchList = await BranchClient.getList();
            this.setState({
                branchArr: branchList.data.success
                    ? branchList.data.result
                    : [],
            });
        } catch (error) {
            this.setState({ errorMsg: "Error retreiving data" });
        }
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const idValidation = await Validation.id(this.state.id);
        const nameValidation = await Validation.name(this.state.name);
        if (!idValidation.success) {
            alert(idValidation.message);
        } else if (!nameValidation.success) {
            alert(nameValidation.message);
        } else {
            const sign = {
                id: this.state.id,
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2,
                name: this.state.name,
                phone: this.state.phone,
                gender: this.state.gender,
                birth_year: this.state.birth_year,
                birth_month: this.state.birth_month,
                birth_day: this.state.birth_day,
                _branch: this.state._branch,
                create_at: this.state.create_at,
                update_at: this.state.update_at,
            };
            try
            {
                const res = await UserClient.create(sign);

                if (res.data.success == true) {
                    alert(res.data.message);
                    this.props.history.push("/");
                } else {
                    alert(res.data.message);
                }
            }
            catch(err)
            {
                console.log(err);
                alert(err.message + "실패");
            }
        }
    };

    render() {
        let yearArr = [];

        const thisyear = moment().format("yyyy");

        for (var i = 0; i < 82; i++) {
            yearArr.push(thisyear - i);
        }

        // const year = [1940,1941,1942,1943,1944,1945,1946,1947,1948,1949,1950,1951,1952,1953,1954,1955,1956,1957,1958,1959,1960,1961,1962,1963,1964,1965,1966,1967,1968,1969,1970,1971,1972,1973,1974,1975,1976,1977,1978,1979,1980,1981,1982,1983,1984,1985,1986,1987,1988,1989,1990,1991,1992,1993,1994,1995,1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021];
        const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        const day = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
        ];

        return (
            <form
                className="form m-10"
                id="contact-form"
                onSubmit={this.onSubmit}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                ID&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_subject"
                                type="text"
                                name="id"
                                placeholder="아이디를 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                PASSWORD&nbsp;
                                <span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password"
                                placeholder="비밀번호를 입력하세요"
                                rows="4"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                PASSWORD CHECK&nbsp;
                                <span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="password"
                                name="password2"
                                placeholder="비밀번호를 입력하세요"
                                rows="4"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                NAME&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="text"
                                name="name"
                                placeholder="이름을 입력하세요"
                                rows="4"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                EMAIL&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_subject"
                                type="text"
                                name="email"
                                placeholder="이메일을 입력하세요"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    {/* <DateDate /> */}
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                BIRTH&nbsp;<span className="colorred">*</span>
                            </p>
                            <div className="form-group row">
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        name="birth_year"
                                        onChange={this.onChange}
                                        defaultValue={this.state.birth_year}
                                    >
                                        <option value="" disabled>
                                            년
                                        </option>
                                        {yearArr.map((year, index) => (
                                            <option
                                                key={index}
                                                value={year}
                                                name="birth_year"
                                                onChange={this.onChange}
                                            >
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        name="birth_month"
                                        onChange={this.onChange}
                                        defaultValue={this.state.birth_month}
                                    >
                                        <option value="" disabled>
                                            월
                                        </option>
                                        {month.map((month, index) => (
                                            <option
                                                key={index}
                                                value={month}
                                                name="birth_month"
                                                onChange={this.onChange}
                                            >
                                                {month}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-sm-4">
                                    <select
                                        className="custom-select"
                                        name="birth_day"
                                        onChange={this.onChange}
                                        defaultValue={this.state.birth_day}
                                    >
                                        <option value="" disabled>
                                            일
                                        </option>
                                        {day.map((day, index) => (
                                            <option
                                                key={index}
                                                value={day}
                                                name="birth_day"
                                                onChange={this.onChange}
                                            >
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                GENDER&nbsp;<span className="colorred">*</span>
                            </p>
                            <select
                                className="custom-select my-1 mr-sm-2"
                                name="gender"
                                onChange={this.onChange}
                                defaultValue={this.state.gender}
                            >
                                <option value="" disabled>
                                    선택
                                </option>
                                <option
                                    value="여성"
                                    name="gender"
                                    onChange={this.onChange}
                                >
                                    여성
                                </option>
                                <option
                                    value="남성"
                                    name="gender"
                                    onChange={this.onChange}
                                >
                                    남성
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                PHONE&nbsp;<span className="colorred">*</span>
                            </p>
                            <input
                                id="form_message"
                                type="text"
                                name="phone"
                                placeholder="예시) 010-0000-0000"
                                rows="4"
                                required="required"
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group text-left">
                            <p>
                                지점&nbsp;<span className="colorred">*</span>
                            </p>
                            <select
                                className="custom-select"
                                name="_branch"
                                onChange={this.onChange}
                                defaultValue={this.state._branch}
                            >
                                <option value="" disabled>
                                    선택
                                </option>
                                {this.state.branchArr.map((item, index) => (
                                    <option key={index} value={item._id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12 text-center mt-15">
                        <button type="submit" className="btn btn-lg">
                            가입하기
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default withRouter(FromSign);
