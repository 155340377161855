import AxiosClient from "./AxiosClient";

const SuggestionClient = {
  list: async (id) => {
    return await AxiosClient.get(`point/get/${id}`);
  },
  history: async (_user, page, pagePerCount) => {
    return await AxiosClient.get(`point/history/${_user}`, {
      page: page,
      pagePerCount: pagePerCount,
    });
  },
};

export default SuggestionClient;
