import ReactModal from 'react-modal';
import {serverUrl} from '../config/api'

export default function NoticePopupItem ({isOpen, closeModal, closeModalToday, closeModalAllDay, image_url}) {

    function onClose() {
        closeModal();
    }

    function onCloseToday(){
        closeModalToday();
    }
    
    function onCloseAllDay(){
        closeModalAllDay();
    }

    return (
        <div className='notice-popup-bg'>
            <ReactModal className="notice-popup" overlayClassName="notice-popup-overlay" isOpen={isOpen} onRequestClose={closeModal}>
                <div className="notice-popup-image">
                    <img src={`${serverUrl}${image_url}`} style={{"width": "100%", "height": "100%"}}></img>
                </div>
                <div className="notice-popup-button" style={{cursor:"pointer"}} onClick={onCloseAllDay}> 앞으로 안보기</div>
                <div className="notice-popup-button" style={{cursor:"pointer"}} onClick={onCloseToday}> 1일 동안 안보기 </div>
                <div className="notice-popup-button" style={{cursor:"pointer"}} onClick={onClose}> 닫기 </div>

            </ReactModal>
        </div>
    );
}