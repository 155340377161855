import React, { Component } from "react";
import Aside from "../components/Aside";
// import Calendar from '../components/Calendar';
import FormBook from "../components/FormBook";
import jwt_decode from "jwt-decode";
import UserClient from "../utils/Http/UserClient";
import BranchClient from "../utils/Http/BranchClient";

class Book extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: "",
    };
  }

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      // console.log(decoded);
      const response = await UserClient.get(decoded._id);
      if (!response.data.success) {
        alert("계정 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.loadBanner(response.data.result._branch._id);
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  loadBanner = async (_branch) => {
    try {
      const response = await BranchClient.getBanner(_branch);

      // const test = `<b style='color:white !important'>비밀번호 0000</b>\n연주회 5/1\n상담가능시간 14:00~22:00`;
      // console.log(response.data.result.banner);
      this.setState({
        banner:
          response.data.success && response.data.result.banner
            ? response.data.result.banner
            : "",
      });
    } catch (err) {
      console.log(err);
      alert("지점 정보를 불러올 수 없습니다.");
    }
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Aside hasBanner={this.state.banner !== "" ? true : false} />
        <div className="togo-side-content" style={{ width: "100%" }}>
          {this.state.banner == "" ? null : (
            <div className="banner display-enter">
              <div
                className="banner-text"
                style={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: this.state.banner }}
              />
            </div>
          )}
          <div className="container">
            <div className="pt-70"></div>
            <section className="about pb-100">
              <div className="container pt-20" id="mw-1000">
                <div className="row text-center m-10">
                  <div className="col-md-12 text-center">
                    <div className="mb-20">
                      <h6>예약하기</h6>
                    </div>
                    <FormBook />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Book;
