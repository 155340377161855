import axios from "axios";
import { apiUrl } from "../../config/api";

const AxiosClient = {

    get: async (uri, data) => {
        var query = '';
        if(data)
        {
            query = '?';
            Object.keys(data).forEach((key) => {
                if(Array.isArray(data[key])){
                    data[key].forEach((x) => {
                        query += `${key}=${x}&`;
                    });
                }
                else {
                    query += `${key}=${data[key]}&`;
                }
            })
            query = query.slice(0, -1);
        }

        const result = await axios.get(`${apiUrl}/${uri}${query}`,
        {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if(result.status !== 200) {
            console.log('http status error, code : ' + result.status);
        }

        if(result.data.success === false) {
            console.log(uri +  ' Error, Message : ' + result.data.message);
        }

        return result;
    },

    post: async (uri, data) => {
        const result = await axios.post(`${apiUrl}/${uri}`, data,
        {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if(result.status !== 200) {
            console.log('http status error, code : ' + result.status);
        }

        if(result.data.success === false) {
            console.log(uri +  ' Error, Message : ' + result.data.message);
        }

        return result;
    },

    patch: async (uri, data) => {
        const result = await axios.patch(`${apiUrl}/${uri}`, data,
        {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if(result.status !== 200) {
            console.log('http status error, code : ' + result.status);
        }

        if(result.data.success === false) {
            console.log(uri +  ' Error, Message : ' + result.data.message);
        }

        return result;
    },

    del: async (uri, data) => {
        const result = await axios.delete(
            `${apiUrl}/${uri}`,
            {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
                data: data
            }
        );

        if(result.status !== 200) {
            console.log('http status error, code : ' + result.status);
        }

        if(result.data.success === false) {
            console.log(uri +  ' Error, Message : ' + result.data.message);
        }
        return result;
    },

}

export default AxiosClient;