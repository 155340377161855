import React, { Component } from "react";
import moment from "moment";
import Aside from "../components/Aside";
import TableTakeLesson from "../components/TableTakeLesson";
import { Link } from "react-router-dom";
import mlblogo from "../img/mlblogo.png";
import jwt_decode from "jwt-decode";
import UserClient from "../utils/Http/UserClient";
import BranchClient from "../utils/Http/BranchClient";
import SuggestionClient from "../utils/Http/SuggestionClient";
import { AuthContext } from "../components/AuthenticatedComponent";

class Suggestion extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      banner: "",
      content: "",
    };
  }

  componentDidMount = async () => {
    try {
      const token = localStorage.getItem("token");
      const decoded = jwt_decode(token);
      // console.log(decoded);
      const response = await UserClient.get(decoded._id);
      if (!response.data.success) {
        alert("계정 정보를 불러올 수 없습니다.\n" + response.data.message);
        return;
      }

      this.setState({
        banner: "",
        content: "",
      });
    } catch (err) {
      console.log(err);
      alert("계정 정보를 불러올 수 없습니다.");
    }
  };

  loadBanner = async (_branch) => {
    try {
      const response = await BranchClient.getBanner(_branch);

      // const test = `<b style='color:white !important'>비밀번호 0000</b>\n연주회 5/1\n상담가능시간 14:00~22:00`;
      // console.log(response.data.result.banner);
      this.setState({
        banner:
          response.data.success && response.data.result.banner
            ? response.data.result.banner
            : "",
      });
    } catch (err) {
      console.log(err);
      alert("지점 정보를 불러올 수 없습니다.");
    }
  };

  onClick = async (e) => {
    e.preventDefault();

    try {
      const content = document.getElementById("content").value;
      if (!content) {
        alert("내용을 입력해주세요.");
        return;
      }

      var response = await SuggestionClient.create(this.context._id, content);
      if (response.data.success == false) {
        alert(response.data.message);
        return;
      }

      alert("작성 완료되었습니다.");
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  render() {
    return (
      <div className="d-flex" id="wrapper">
        <Aside hasBanner={this.state.banner !== "" ? true : false} />

        <div className="togo-side-content" style={{ width: "100%" }}>
          {this.state.banner == "" ? null : (
            <div className="banner display-enter">
              <div
                className="banner-text"
                style={{ color: "white" }}
                dangerouslySetInnerHTML={{ __html: this.state.banner }}
              />
            </div>
          )}
          {/* <div className="text-center header-logo">
            <Link to="/">
              <img
                style={{
                  maxWidth: 240,
                  marginBottom: "30px",
                  padding: "5px 10px",
                }}
                src={mlblogo}
                alt=""
                id="logoimage"
              />
            </Link>
          </div> */}
          <div className="container">
            <div className="pt-70"></div>
            <section className="about pb-100">
              <div className="container pt-20" id="mw-880">
                <div className="row m-10 text-center">
                  <div className="col-md-12 text-center">
                    <div className="container mb-40">
                      <h6 className="">홈페이지 개선 요청사항</h6>
                    </div>
                    <p>
                      예약사이트 관련 건의사항을 남겨주세요. 작성해주신 내용은
                      사이트 관리자에게 전달됩니다.
                    </p>
                    <textarea
                      type="text"
                      className="form-control"
                      id="content"
                      name="content"
                      required="required"
                      placeholder="내용을 입력해 주세요."
                      rows={10}
                    />
                    <button
                      type="button"
                      className="btn btn-lg m-10"
                      onClick={this.onClick}
                    >
                      작성완료
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default Suggestion;
